a {
  text-decoration: none;
  color: black;
}

.header-bg {
  background: #fff;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(217, 217, 217, 1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(217, 217, 217, 1);
  box-shadow: 0px 3px 5px 0px rgba(217, 217, 217, 1);
}

/* center a div */
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.table-row-light {
  background-color: transparent !important;
}

.table-row-dark {
  background-color: #d9d9d9 !important;
}